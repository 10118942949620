require('@iframe-resizer/parent')
const iFrameResize = window['iFrameResize'];

// ODEventsEmbed("od-venue-ownership-1260-events-iframe");
(function () {

  function postMessageReceived(event, iframe){
    var payload = event.data;

    if(typeof(payload) === "object" && payload.from === "Opendate"){
      var command = payload.command;
      var data = payload.data;

      switch (command) {
        case "confirmLoaded":
          iframe.scrollIntoView();
          break;
        default:
          console.log("Unknown command \"" + command + "\" received from Opendate");
      }
    }
  }

  if(typeof ODEventsEmbed !== 'function'){
    window.ODEventsEmbed = function(iframeID, options){
      var iframe = document.getElementById(iframeID);
      var contentWindow = iframe.contentWindow;
      var options = (options || {});

      iFrameResize({license: "12ajjdewwwy-26rnhw2943-1s7g1u8ma0i"}, `#${iframeID}`);

      var wrappedPostMessageReceived = function(event){
        postMessageReceived(
          event,
          iframe
        );
      }

      // https://robertnyman.com/html5/postMessage/postMessage.html
      if (window.addEventListener) {
        window.addEventListener("message", wrappedPostMessageReceived, false);
      } else {
        window.attachEvent("onmessage", wrappedPostMessageReceived);
      }
    };
  }
})();